<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="保险类型" prop="type" >
        <a-radio-group v-model="form.type">
          <a-radio :value="1">美签</a-radio>
          <a-radio :value="2">申根</a-radio>
          <a-radio :value="3">英国境内</a-radio>
          <a-radio :value="4">留学险</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="出发区域" prop="departureArea" >
        <a-radio-group v-model="form.departureArea">
          <a-radio :value="1">境内投保</a-radio>
          <a-radio :value="2">境外投保</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="海报图" prop="logo" >
        <file-upload  type="image" :defaultList="form.logoArr" :count="1"
                    @input="getImg($event, 'logo')"></file-upload>
        <span style="color: red">建议上传图片尺寸：185 x 199 px</span>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input v-model="form.title" placeholder="请输入内容"/>
      </a-form-model-item>
      <a-form-model-item label="跳转链接" prop="pagePath" >
        <a-input v-model="form.pagePath" placeholder="请输入跳转链接"/>
      </a-form-model-item>
      <a-form-model-item label="价格" prop="price" >
        <a-input-number :min="0" style="width: 300px" v-model="form.price" placeholder="请输入价格" />
      </a-form-model-item>
      <a-form-model-item label="内容" prop="content" >
        <a-input type="textarea" v-model="form.content" />
      </a-form-model-item>
      <a-form-model-item label="标签" prop="label" >
        <a-select v-model="form.label">
          <a-select-option v-for="item in labelList" :value="item.id">{{item.dictLabel}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="保险公司" prop="insuranceCompany" >
        <a-select v-model="form.insuranceCompany">
          <a-select-option v-for="item in companyList" :value="item.id">{{item.dictLabel}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="销量" prop="saleAmount" >
        <a-input-number disabled :min="0" style="width: 300px" v-model="form.saleAmount" placeholder="请输入销量" />
      </a-form-model-item>
      <a-form-model-item label="排序" prop="sort" >
        <a-input-number :min="0" style="width: 300px" v-model="form.sort" allow-clear />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getInsurance, addInsurance, updateInsurance } from '@/api/insurance/insurance'
import Editor from '@/components/Editor'
import {getDicts} from "@/api/system/dict/data";
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      labelList:[],
      companyList:[],
      // 表单参数
      form: {
        id: null,
        type: null,
        departureArea: null,
        logo: null,
        logoArr:[],
        logoCount:1,
        title: null,
        price: null,
        content: null,
        pagePath:null,
        label: null,
        insuranceCompany: null,
        saleAmount: null,
        createTime: null,
        remark: null,
        sort:null,
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        type: [
          { required: true, message: '保险类型不能为空', trigger: 'change' }
        ],
        departureArea: [
          { required: true, message: '出发区域不能为空', trigger: 'change' }
        ],
        logo: [
          { required: true, message: '海报图不能为空', trigger: 'blur' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        pagePath: [
          { required: true, message: '跳转链接不能为空', trigger: 'blur' }
        ],
        price: [
          { required: true, message: '价格不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        label: [
          { required: true, message: '标签不能为空', trigger: 'blur' }
        ],
        insuranceCompany: [
          { required: true, message: '保险公司不能为空', trigger: 'blur' }
        ],
        // saleAmount: [
        //   { required: true, message: '销量不能为空', trigger: 'blur' }
        // ]
      }
    }
  },
  filters: {
  },
  created () {
    this.initLabel()
  },
  activated() {
    this.initLabel()
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    initLabel(){
      getDicts('insurance_label').then(res=>{
        this.labelList = res.data
      })
      getDicts('insurance_company').then(res=>{
        this.companyList = res.data
      })
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        type: null,
        departureArea: null,
        logo: null,
        logoArr:[],
        pagePath:null,
        logoCount:1,
        title: null,
        price: null,
        content: null,
        sort:null,
        label: null,
        insuranceCompany: null,
        saleAmount: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getInsurance({"id":id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        let that = this
        this.form.logoArr = []
        if(this.form.logo){
          this.form.logoArr.push({
            uid: guid(8, 10),
            name: that.form.logo,
            status: 'done',
            halfUrl: that.form.logo,
            url: that.form.logo,
            path: that.form.logo
          })
        }
      })
    },

    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info.map(ele => { return ele.url }).join(",")
          this.form[`${fieldName}Arr`] = info
        }
      }
    },

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateInsurance(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addInsurance(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
