import request from '@/utils/request'


// 查询保险列表
export function listInsurance(query) {
  return request({
    url: '/insurance/insurance/list',
    method: 'get',
    params: query
  })
}

// 查询保险分页
export function pageInsurance(query) {
  return request({
    url: '/insurance/insurance/page',
    method: 'get',
    params: query
  })
}

// 查询保险详细
export function getInsurance(data) {
  return request({
    url: '/insurance/insurance/detail',
    method: 'get',
    params: data
  })
}

// 新增保险
export function addInsurance(data) {
  return request({
    url: '/insurance/insurance/add',
    method: 'post',
    data: data
  })
}

// 修改保险
export function updateInsurance(data) {
  return request({
    url: '/insurance/insurance/edit',
    method: 'post',
    data: data
  })
}

// 删除保险
export function delInsurance(data) {
  return request({
    url: '/insurance/insurance/delete',
    method: 'post',
    data: data
  })
}
